import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import type { AsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { ThunkHttpClient } from '../utils/httpThunkClient'
import { stat } from 'fs'
import { popMessage } from '../../App'
import moment from 'moment'


// First, create the thunk
// Define a type for the slice state
export const productWiseCountReport = ThunkHttpClient.get('ticketinventory/productWiseCountReport',
    (data) => ("productwisereport?" + (data.pnr ? 'pnr='+data.pnr : '' ) 
    +(data.expiry ? '&expiry='+data.expiry : '' ) 
    + (data.date ? '&date='+data.date : '' )  
    + (data.travelDate ? '&travelDate='+data.travelDate : '' )  
    
    +(data.ticketnature ? '&ticketnature='+data.ticketnature : '' ) 
    ))

export const productDatetimeWiseCountReport = ThunkHttpClient.get('ticketinventory/productDatetimeWiseCountReport',
    (data) => ("productdatetimewisereport?" + (data.pnr ? 'pnr='+data.pnr : '' ) 
    +(data.expiry ? '&expiry='+data.expiry : '' ) 
    + (data.date ? '&date='+data.date : '' )  
    +('&productId='+data.id ) 
    ))


export const downloadReport = ThunkHttpClient.get('ticketinventory/downloadlist',
    (data) => ("downloadlist"))
                    
export const getDownloadCountByInvoice = ThunkHttpClient.get('ticketinventory/getDownloadCountByInvoice',
    (data) => ("getDownloadCountByInvoice?invoiceRef="+data))

    

export const updateTicketsStatusForPreviewPage = ThunkHttpClient.post('ticketinventory/updateTicketsStatusForPreviewPage',
    ({body, status}) => ({ url: "updateTicketInfoStatus", body: body }))


    
export const downloadInventoryFile = ThunkHttpClient.download('ticketinventory/downloadfile',
    ({ id, customer, invoice, productName }) => (`downloadfile?filename=${productName}-${invoice}&id=${id}`))


export const downloadSampleInventoryFile = ThunkHttpClient.download('ticketinventory/sampledownloadfile',
    (parser) => ("downloadsamepleparserfile?parser=" + parser))


export const deleteFileByIdAndComponentName = ThunkHttpClient.delete('ticketinventory/deleteFileByIdAndComponentName',
    ({id, status}) => (`deleteFileByIdAndComponentName?componentName=INVENTORY_UPLOAD&deleteObjectFromBucket=true
        &deleteContent=${status !== 'processeed_error'}&id=${id}`))
    



export const downloadTicketRequest = ThunkHttpClient.post('ticketinventory/downlaodRequest',
    ({ data }) => ({ url: "downlaodRequest", body: data }), (dispatch, data) => {
        dispatch(setInvenotryProductPopupStates({ download: undefined, statement: undefined }))
        dispatch(productWiseCountReport(data.filterData))    
})

export const getTicketAvailability = ThunkHttpClient.post('ticketinventory/getTicketAvailability',
    ({ data }) => ({ url: "getTicketAvailability", body: data }), (dispatch, data) => {
      // return data;
})


export const downloadExcel = ThunkHttpClient.download('ticketinventory/downloadExcel',
    ({product_id, product_name}) => ("downloadExcel?productId=" + product_id + "&fileName=" + product_name))


export const downloadPreviewExcel = ThunkHttpClient.download('ticketinventory/downloadExcelpreview',
    ({id, status}) => ("downloadTicketInfo?uploadId=" + id + "&status=" + status))



// Define the initial state using that type
const initialState: any = {
    loading: false,
    createingdownload: false,
    inventoryfilterData: {
        ticketnature: 'vp',
    },
    getDownloadCountByInvoiceloading: false,
    selected: undefined,
    filterData: undefined,
    downlaodedCount: -1,
    downloadPreviewExcelloading: false,
    inventoryData: [],
    ticketStatusUpdateloading: '',
    downloadRequestData : [],
    productdatetimewisedata: [],
    invenotryProductPopupStates: {
        view: undefined,
        upload: undefined,
        download: undefined,
        statement: undefined
    },
    filterText: '',
    data: []
}

export const inventorySlice = createSlice({
    name: 'inventory',
    initialState,
    reducers: {

        filterInvetoryResult: (state, action) => {
            state.filterText = action.payload
        },

        setInventoryfilterData: (state, action) => {
            state.inventoryfilterData = action.payload
        },
        

        clearConfirmPopup: (state, action) => {
            state.downlaodedCount = -1
        },
        setInvenotryProductPopupStates: (state, action) => {
            state.invenotryProductPopupStates = {
                ...state.invenotryProductPopupStates, ... action.payload
            }
            state.downlaodedCount = -1;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(productWiseCountReport.fulfilled, (state, action) => {
            state.loading = false;
            if (action && action.payload) {
                state.data = action.payload.data.map((el:any)=>{
                    const lastu  = el.lastu ? el.lastu.split('|') : ['', '' , '']
                    const lastd = el.lastd ? el.lastd.split('|') : ['', '', '']

                    const f = {
                        ...el,
                        total_avail_adult: el.total_adult - el.total_downloaded_adult,
                        total_avail_child: el.total_child - el.total_downloaded_child,
                        total_avail_code_adult: el.total_code_adult - el.total_downloaded_code_adult,
                        total_avail_code_child: el.total_code_child - el.total_downloaded_code_child,
                        lastUploadDate: lastu[2],
                        lastUploadBy: lastu[0],
                        lastUploadByEmail: lastu[1],
                        lastDownloadDate: lastd[2],
                        lastDownloadBy: lastd[0],
                        lastDownloadByEmail: lastd[1],
                    }
                    f.totalaval = f.total_avail_adult+ f.total_avail_child + f.total_avail_code_adult + f.total_avail_code_child;
                    return f;
                })
            }
        })
        builder.addCase(productWiseCountReport.pending, (state, action) => {
            state.filterData = action.meta.arg;
            state.loading = true;
        })
        builder.addCase(productWiseCountReport.rejected, (state, action) => {
            state.loading = false;
        })

        builder.addCase(productDatetimeWiseCountReport.fulfilled, (state, action) => {
            state.productdatetimewiseloading = false;
            if (action && action.payload) {
                state.productdatetimewisedata = action.payload.data.map((el:any)=>{

                    const f  = {
                        ...el,
                        total_avail_adult: el.total_adult - el.total_downloaded_adult,
                        total_avail_child: el.total_child - el.total_downloaded_child,
                        total_avail_code_adult: el.total_code_adult - el.total_downloaded_code_adult,
                        total_avail_code_child: el.total_code_child - el.total_downloaded_code_child,
                        
                    }
                    f.totalaval = f.total_avail_adult+ f.total_avail_child + f.total_avail_code_adult + f.total_avail_code_child;

                    return f;
                })
            }
        })
        builder.addCase(productDatetimeWiseCountReport.pending, (state, action) => {
           // state.filterData = action.meta.arg;
            state.productdatetimewiseloading = true;
        })
        builder.addCase(productDatetimeWiseCountReport.rejected, (state, action) => {
            state.productdatetimewiseloading = false;
        })

        

        builder.addCase(downloadTicketRequest.fulfilled, (state, action) => {
            state.createingdownload = false;
            popMessage({type:'info', content: 'Donwload Request Created! Kindly visit download section to download file'})            
        })
        builder.addCase(downloadTicketRequest.pending, (state, action) => {
            state.createingdownload = true;
        })
        builder.addCase(downloadTicketRequest.rejected, (state, action) => {
            state.createingdownload = false;
        })

        builder.addCase(downloadReport.fulfilled, (state, action) => {
            state.downloadloading = false;
            if (action && action.payload) {
                state.downloadRequestData = action.payload.data;
            }
        })
        builder.addCase(downloadReport.pending, (state, action) => {
            state.downloadloading = true;
        })
        builder.addCase(downloadReport.rejected, (state, action) => {
            state.downloadloading = false;
        })

        builder.addCase(getDownloadCountByInvoice.fulfilled, (state, action) => {
            state.getDownloadCountByInvoiceloading = false;
            if (action && action.payload) {
                state.downlaodedCount = action.payload.data;
            }
        })
        builder.addCase(getDownloadCountByInvoice.pending, (state, action) => {
            state.getDownloadCountByInvoiceloading = true;
            state.downlaodedCount = undefined;
        })
        builder.addCase(getDownloadCountByInvoice.rejected, (state, action) => {
            state.getDownloadCountByInvoiceloading = false;
        })

        builder.addCase(downloadExcel.fulfilled, (state, action) => {
            state.loading = false;
        })

        builder.addCase(downloadExcel.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(downloadExcel.rejected, (state, action) => {
            state.loading = false;
        })

        builder.addCase(downloadPreviewExcel.fulfilled, (state, action) => {
            state.downloadPreviewExcelloading = false;
        })

        builder.addCase(downloadPreviewExcel.pending, (state, action) => {
            state.downloadPreviewExcelloading = true;
        })
        builder.addCase(downloadPreviewExcel.rejected, (state, action) => {
            state.downloadPreviewExcelloading = false;
        })


        

        builder.addCase(updateTicketsStatusForPreviewPage.fulfilled, (state, action) => {
            state.ticketStatusUpdateloading = false;
        })

        builder.addCase(updateTicketsStatusForPreviewPage.pending, (state, action) => {
            state.ticketStatusUpdateloading = action.meta.arg.status;
        })
        builder.addCase(updateTicketsStatusForPreviewPage.rejected, (state, action) => {
            state.ticketStatusUpdateloading = false;
        })
        builder.addCase(deleteFileByIdAndComponentName.fulfilled, (state, action) => {
            state.uploadEntryDeleteLoading = false;
        })

        builder.addCase(deleteFileByIdAndComponentName.pending, (state, action) => {
            state.uploadEntryDeleteLoading = action.meta.arg;
        })
        builder.addCase(deleteFileByIdAndComponentName.rejected, (state, action) => {
            state.uploadEntryDeleteLoading = false;
        })


        builder.addCase(getTicketAvailability.fulfilled, (state, action) => {
            state.getTicketAvailabilityLoading = false;
        })

        builder.addCase(getTicketAvailability.pending, (state, action) => {
            state.getTicketAvailabilityLoading = action.meta.arg;
        })
        builder.addCase(getTicketAvailability.rejected, (state, action) => {
            state.getTicketAvailabilityLoading = false;
        })

        

        

        
        
        
        
    },
})


export const { setInvenotryProductPopupStates, filterInvetoryResult, clearConfirmPopup, setInventoryfilterData } = inventorySlice.actions
export default inventorySlice.reducer