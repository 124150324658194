import React, { useEffect, useState } from 'react';
import { Alert, Col, DatePicker, Divider, InputNumber, Modal,Drawer, Popconfirm, Popover, Radio, Row, Select, Space, Switch, Table, Tag, message} from 'antd';
import type { TableColumnsType, TableProps } from 'antd';
import { Button, Form, Input, Typography, Card } from 'antd';

import {
  CheckOutlined,
  SearchOutlined,
  DownloadOutlined,
  SettingOutlined,
  ClearOutlined,
  ReloadOutlined
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { clearConfirmPopup, downloadSampleInventoryFile, downloadTicketRequest, filterInvetoryResult, getDownloadCountByInvoice, getTicketAvailability, productWiseCountReport, setInvenotryProductPopupStates } from './slice-ticketInventory';
import moment from 'moment';
import { MasterSelect } from '../common/MasterSelect';
import ColumnGroup from 'antd/es/table/ColumnGroup';
import Column from 'antd/es/table/Column';
import { getMasterTypeSelectorValue } from '../common/slice-master';
import { FileuploadCommon } from '../common/FileUpload';
import { Roles } from '../common/Role';
import { PreAuthz } from '../security/Authz';
import { TicketListForProduct } from './TicketListForProductwise';


export const ModalDownladTickets = ({open, onClose, bucketData}: any) => {
 const [form] = Form.useForm();
 const [state, setState] = useState();
  const { masterSelectValues, masterTypeData } = useSelector((state: any) => state.master)
  const options = (masterSelectValues['Trip Ticket Inventory'] || []).find((value: any) => value.id === open.id)
  const downloadMapper = JSON.parse(options?.other || '{"downloadMapper":[]}').downloadMapper
  const [modal, contextHolder] = Modal.useModal();
  const { createingdownload, filterData, getTicketAvailabilityLoading, inventoryfilterData, downlaodedCount } = useSelector((state: any) => state.ticketInventory)
 const dispatch = useDispatch();
 const parserWithLabel  = masterTypeData.writters.reduce((map:any, obj:any) => {
    map[obj.name.toUpperCase()] = {label: obj.label, name: obj.name };
    return map;
 }, {});
  const ticketCategory =  bucketData && bucketData.length > 0 ? bucketData[0].ticketCategory : undefined
useEffect(()=>
{
  if(downlaodedCount !== undefined) {
    if(downlaodedCount>0) {
      modal.confirm({
        title: 'Confirm',
        content: 'Tickets are already downloaded for the invoice',
        okText: 'Procced',
        onOk: (el)=> {
          //console.log(el);
          el()
          dispatch(downloadTicketRequest({
    data: { list: bucketData, travelDate: inventoryfilterData.travelDate, productId: open.id, productName: open.product_name, ...form.getFieldsValue(), customTemplate: form.getFieldValue('templateSelection') === 'orional' ? 'UploadedFormatPdfWritter' : form.getFieldValue('customTemplate') }
    , filterData: filterData
  }))
        },
        onCancel: (el)=> { el();  dispatch(clearConfirmPopup(undefined))},
        footer: (_, { OkBtn, CancelBtn }) => (
          <>
            <CancelBtn  />
            <OkBtn />
          </>
        ),
      });
    } else  if(downlaodedCount === 0) {
        dispatch(downloadTicketRequest({
        data: { 
          list: bucketData,
          travelDate: inventoryfilterData.travelDate,
          productId: open.id, productName: open.product_name,
          ...form.getFieldsValue(), customTemplate: form.getFieldValue('templateSelection') === 'orional' ? 'UploadedFormatPdfWritter' : form.getFieldValue('customTemplate'), ...filterData }
        , filterData: filterData
      }))
    }
  }


},[downlaodedCount])

  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: 'Value is not a valid number!',
    },
    number: {
      range: 'Value must be between ${min} and ${max}',
    },
  };



  return <Modal open={open} onCancel={onClose} className='doenloadPDFModal'
  width={600}
    title={open?.product_name}
    footer={(_, { OkBtn, CancelBtn }) => (
      <>
        <Button 
         type="primary" htmlType="submit"  disabled={form.getFieldValue('templateSelection')===undefined} 
         onClick={() => 
          {
           const adlc = form.getFieldValue('ticketAdultCount');
           const cdlc = form.getFieldValue('ticketChildCount');
           if(!(adlc >0 || cdlc >0) && bucketData === undefined) {
            message.error({content: 'Enter adult or child ticket count'})
            return;
           }

           let filed = ['customTemplate', 'invoice', 'reason'];
           if (cdlc >0 && bucketData === undefined) {
             filed.push('ticketChildSellPrice');
             filed.push('ticketChildCount');
           } 

           if (adlc > 0 && bucketData === undefined) {
             filed.push('ticketAdultSellPrice');
             filed.push('ticketAdultCount');
           }
           
           form
             .validateFields(filed)
             .then((e) => {

                  if(inventoryfilterData.ticketnature === 'nvp') {
                    dispatch(getDownloadCountByInvoice(form.getFieldValue('invoice')))
                    return;
                  }
                  
                   

                  dispatch(getTicketAvailability({
                    data: { list: bucketData, productId: open.id, 
                      productName: open.product_name, ...form.getFieldsValue(), 
                      travelDate: inventoryfilterData.travelDate,
                      customTemplate: form.getFieldValue('templateSelection') === 'orional' ? 'UploadedFormatPdfWritter' : form.getFieldValue('customTemplate') }
                    , filterData: filterData
                  })).then((data:any) => {
                    
                    if(data.error && data.error.message !== undefined) {
                      return;
                    }
                    const t = data.payload.data;
                    if((t.pdfTypeCount === 0 && form.getFieldValue('ticketCategory') === 'mediacode')
                      || (t.mtypeCount === 0 && form.getFieldValue('ticketCategory') === 'pdf')) {
                      dispatch(getDownloadCountByInvoice(form.getFieldValue('invoice')))
                    } else {
                     
                      modal.confirm({
                        title: 'Confirm your request to download as per suggestion', 
                        content: <>
                        <Alert message="We have availability suggestion as per expiry date, you should download tickets as per below suggestion" type="error" />
                        <Table pagination={false} dataSource={[
                          {
                            count: t.pdfTypeCount, sell: t.minPdfSellPrice, type: 'Pdf Tickets'
                          },
                          {
                            count: t.mtypeCount, sell: t.minMSellPrice, type: 'Media Tickets'
                          }
                          ]} >
                            <Table.Column title="Tickets Type"  dataIndex={'type'}/>
                            <Table.Column title="Available expiry suggestion count" dataIndex={'count'} />
                            <Table.Column title="Min sell price"  dataIndex={'sell'}/>
                        </Table>
                        </>,
                        okText: 'Confirm &  Procced please',
                        cancelText: 'Cancel & modify request',
                        onOk: () => {
                          dispatch(getDownloadCountByInvoice(form.getFieldValue('invoice')))
                        }
                  })
                }
                });
              
             
             })
             .catch((e) => console.log(e));
          }
        }
          loading={getTicketAvailabilityLoading === true || createingdownload === true || getDownloadCountByInvoice === true}
        >
          <DownloadOutlined />
          Downlaod
        </Button>
        <Button onClick={() => {form.resetFields(); setState(undefined);}} ><ClearOutlined /> Clear Filter</Button>
      </>
    )}
    destroyOnClose>
      {createingdownload}
      {getDownloadCountByInvoice}
    {contextHolder}
    <Form
      name="tiproductdownlaodform"
      form={form}
      labelCol={{ span: 12 }}
      wrapperCol={{ span: 12 }}
      onValuesChange={e => setState(e)}
      validateMessages={validateMessages}
      initialValues={{ type: 'adult', ticketCategory: ticketCategory, ticketChildCount:0,ticketAdultCount:0 }}
      requiredMark
    >
      <Row>
        <Col span={24} className='mobileViewFullScreen'>
          <Form.Item
            name="ticketCategory"
            label="Select Ticket Category"

            rules={[
              {
                required: true,
                message: "Select Ticket Category",
              },
            ]}
          >
            <Radio.Group disabled={ticketCategory} onChange={(e) => {
              form.resetFields(['templateSelection','customTemplate', 'ticketAdultCount',
                'ticketAdultSellPrice', 'ticketChildCount', 'ticketChildSellPrice', 'invoice', 'reason']);
              if (e.target.value !== 'pdf') {
                form.setFieldValue('templateSelection', 'custome');
              }
            }}>
              <Radio value="pdf">Pdf</Radio>
              <Radio value="mediacode">Media Code</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={12} className='mobileViewFullScreen'>
          <Form.Item
            name="templateSelection"
            label="Select Template Type"
            
            rules={[
              {
                required: true,
                message: "Select Template Type",
              },
            ]}
          >
            <Radio.Group disabled={form.getFieldValue('ticketCategory') === 'mediacode'}  onChange={e=>{
              form.resetFields(['customTemplate', 'ticketAdultCount', 
                'ticketAdultSellPrice', 'ticketChildCount', 'ticketChildSellPrice', 'invoice', 'reason']);
          }}>
            <Radio value="orional">Orional</Radio>
            <Radio value="custome"> Custom Template </Radio>
          </Radio.Group>
          </Form.Item>
        </Col>
        <Col span={12} className='mobileViewFullScreen'>
          <Form.Item
            name="customTemplate"
            label="Select Custom Template"
            rules={[
              ...(form.getFieldValue('templateSelection') === 'orional' ? []:
              [{
                required: true,
                message: "Select Custom Template",
              }])
            ]}
          >
            <Select disabled={form.getFieldValue('templateSelection') === 'orional'} placeholder="Select downlaod template">
              {downloadMapper.map((e: any) => <Select.Option key={e} value={e}>
                
              {parserWithLabel[e.toUpperCase()].label}
            </Select.Option>)} 
            </Select>
          </Form.Item>
        </Col>
      </Row>
          {bucketData ? 
          
          
          <div className='dataTableMobileView'>
            <Table dataSource={bucketData}>
                  <Table.Column title="PNR" dataIndex="key"  />
                  <Table.Column title="Date" dataIndex="date"  />
                  <Table.Column title="Time" dataIndex="time"  />
                  <Table.Column title="Type" dataIndex="type"  />
                  <Table.Column title="count" dataIndex="count"  />
                  <Table.Column title="Selling Price per ticket" dataIndex="sellingprice"  />
            </Table>
          </div>
          
          : <>
        <Row>
        <Col span={12} className='mobileViewFullScreen'>
            <Form.Item
              name="ticketAdultCount"
                label={`Enter ticket adult count (0 - ${form.getFieldValue('ticketCategory') === 'pdf' ? 
            open?.total_avail_adult : open?.total_avail_code_adult})`}
              rules={
                [{
                  type: 'number', min: 0, max: form.getFieldValue('ticketCategory') === 'pdf' ?
                    open?.total_avail_adult : open?.total_avail_code_adult }, {required: true}]
              }
            >
              <InputNumber placeholder='Enter ticket count' />
            </Form.Item>
          </Col>
          <Col span={12} className='mobileViewFullScreen'>
          <Form.Item
            name="ticketAdultSellPrice"
            label="Enter adult sell price"
            rules={[
              {
                required: true,
                message: "Enter adult sell price",
              },
            ]}
            >
            <InputNumber placeholder='Enter adult sell price' />
          </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12} className='mobileViewFullScreen'>
          <Form.Item
            name="ticketChildCount"
                label={`Enter ticket child count (0 - ${form.getFieldValue('ticketCategory') === 'pdf' ?
              open?.total_avail_child : open?.total_avail_code_child})`}
            rules={[{
              type: 'number', min: 0, max: form.getFieldValue('ticketCategory') === 'pdf' ?
                open?.total_avail_child : open?.total_avail_code_child }, { required: true }]}
          >
            <InputNumber type='number' placeholder='Enter ticket count' />
          </Form.Item>
          </Col>
          <Col span={12} className='mobileViewFullScreen'>
          <Form.Item
            name="ticketChildSellPrice"
            label="Enter child sell price"
            rules={[
              {
                required: true,
                message: "Enter child sell price",
              },
            ]}
          >
            <InputNumber placeholder='Enter child sell price' />
          </Form.Item>
          </Col>
        </Row> </>}
      <Form.Item
      name="invoice"
      label="Add invoice no"
      rules={[
        {
          required: true,
          message: "Kindly add invoice",
        },
      ]}
      >
        <Input type='text' placeholder='Kindly add invoice' />
    </Form.Item>
    <Form.Item
        name="customer"
        label="Add Customer"
        rules={[
          {
            required: true,
            message: "Kindly add customer",
          },
        ]}
      >
        <Input type='text' placeholder='Input customer' />
      </Form.Item>
      <Form.Item
        name="reason"
        label="Add reason"
        
      >
        <Input type='text' placeholder='Kindly add reason' />
      </Form.Item>
    </Form>
  </Modal>
}
