import dayjs from "dayjs";
import moment from "moment";


const isDateInFormat = (date:string, format:string) => {
    try {
        const d = moment(date, format,true);
        if(d.isValid()) 
            return d
        return false
    } catch (e) {
        return false
    }
}
const dateParse = (date:string, format:string) => {
    return moment(date, format,true)
}

export const formatDateInTimeZone = (date:string) => {
    
    

try {
    let datef = isDateInFormat(date, 'DD-MM-YYYY')
    if(datef) {
       return convertUTCDateToLocalTimezone(datef.toISOString())
    }
    
    datef = isDateInFormat(date, 'YYYY-MM-DDTHH:mm:ss.SSSSSS')
    if(datef) {
        return convertUTCDateToLocalTimezone(date+'Z')
     }
    
    return convertUTCDateToLocalTimezone(moment(date).toLocaleString());
   
} catch (e) {
    console.log(e);
 return 'NAN'
}
}

// Function to get the client's timezone
function getClientTimezone(): string {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

// Function to convert UTC date to the client's local timezone
function convertUTCDateToLocalTimezone(utcDate: string): string {
    // Create a Date object from the UTC date string
    const date = new Date(utcDate);

    // Get the client's timezone
    const timeZone = getClientTimezone();

    // Use Intl.DateTimeFormat to format the date in the client's local timezone
    const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        timeZone: timeZone,
        hour12: false
    };

    const formatter = new Intl.DateTimeFormat('en-gb', options);
    return formatter.format(date).toUpperCase();
}



export const  getTimeDropdownOptionsValue = () => {

    const timepicketoption = [] as any;
    


    Array(12).fill(1).forEach((e, i:any)=>{
    if(i<10) {
        i = '0'+i;
    }
    timepicketoption.push({label: `${i}:00 am`, value: `${i}:00 am`});
    timepicketoption.push({label: `${i}:30 am`, value: `${i}:30 am`});
    })
    Array(12).fill(1).forEach((e, i:any)=>{
    if (i==0) {
        i =12;
    }
    if(i<10) {
        i = '0'+i;
    }
    timepicketoption.push({label: `${i}:00 pm`, value: `${i}:00 pm`});
    timepicketoption.push({label: `${i}:30 pm`, value: `${i}:30 pm`});
    })

    return timepicketoption
    
}

export const removeNullQueryParam  = (url:string) => {
    const urlSlice = url.split('?');
    let queryString = urlSlice[1]; // Extract the query string part
    if (!queryString) return url; // If no query params, return an empty object
    
    const queryParams = queryString.split('&').reduce((params:any, param) => {
        const [key, value] = param.split('='); // Split into key and value
        params[decodeURIComponent(key)] = decodeURIComponent(value); // Decode and store
        return params;
    }, {});

    queryString = Object.keys(queryParams)
        .filter(key => queryParams[key] != null && queryParams[key] !== '' && queryParams[key] != 'null' && queryParams[key] != 'undefined')  // Exclude null or empty params
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
        .join('&');

    // Return the full URL with the query string
    return queryString ? `${urlSlice[0]}?${queryString}` : urlSlice[0];
}


export const isEmptyOrNull = (obj:any) => obj === undefined || obj === '' || obj === null