import React, { useEffect, useState } from 'react';
import { Alert, Col, DatePicker, Divider, InputNumber, Modal,Drawer, Popconfirm, Popover, Radio, Row, Select, Space, Switch, Table, Tag, message, Upload} from 'antd';
import type { TableColumnsType, TableProps } from 'antd';
import {  Form} from 'antd';

import { useDispatch, useSelector } from 'react-redux';
import {  downloadSampleInventoryFile } from './slice-ticketInventory';
import { FileuploadCommon } from '../common/FileUpload';
import dayjs from 'dayjs';


export const ModalUploadTickets = ({ open, onClose }: any) => {
  const { masterSelectValues, masterTypeData } = useSelector((state: any) => state.master)
const parserWithLabel  = masterTypeData.ticketparser.reduce((map:any, obj:any) => {
  map[obj.name.toUpperCase()] = {label: obj.label, type: obj.type };
  return map;
}, {});
  const options = (masterSelectValues['Trip Ticket Inventory'] || []).find((value: any) => value.id === open.id)
  const [formData, setFormData] = useState({})
  const dispatch = useDispatch();
  const uploadMapper = JSON.parse(options?.other || '{"uploadMapper":[]}').uploadMapper
  const [form] = Form.useForm();
  return <Modal open={open} onCancel={onClose}
    width={700} className='uploadPDFModal'
    title={open?.product_name}
    footer={(_, { OkBtn, CancelBtn }) => (
      <>
        <CancelBtn />
      </>
    )}
    destroyOnClose>
    {uploadMapper.length<=0?
    <Alert message={`No parser found for product please contact developer to support parser for "${open.product_name}"`}
        type='error' /> : <Alert message="Once you will select the ticket it will start uploading" type="warning" />}
    <Divider />
    <Form
      name="tiuploadmodelform"
      onValuesChange={(e:any) => {
        setFormData(l=>({...l,...e}))
      }}
      form={form}
      labelCol={{ span: 12 }}
      wrapperCol={{ span: 12 }}
      initialValues={{ remember: true }}
    >
      <Row>
        <Col span={12} className='mobileViewFullScreen'>
          <Form.Item name="expirebefore" label="Expire Before"
            
            rules={[
              {
                required: true,
                message: "Please select expire date !",
              },
            ]}>
            <DatePicker placeholder='Select Expire Before Date'  minDate={dayjs()}/>
          </Form.Item>
        </Col>
        <Col span={12} className='mobileViewFullScreen'>
          <Form.Item label="Parse" name="parser">
            <Radio.Group>
              { uploadMapper.map((e: string) => (<Radio key={e} value={e}>
                   <Popover content={<>
                   <a onClick={()=>dispatch(downloadSampleInventoryFile(e))}>View Sample</a>
                   </>} trigger="hover">
                   {parserWithLabel[e.toUpperCase()].label}
                   </Popover>
                   </Radio>))}
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      {form.getFieldValue('parser')?<>
      <Row>
        <Col span={12} className='mobileViewFullScreen'>
          <Form.Item name="adultticketcost" label="Cost per adult ticket"
            rules={[
              {
                required: true,
                message: "Please enter cost !",
              },
            ]}>
            <InputNumber placeholder='Cost Per adult ticket' />
          </Form.Item>
        </Col>
        <Col span={5} className='mobileViewFullScreen'>
          <Form.Item name="adultticket" >
            <FileuploadCommon componentName={'INVENTORY_UPLOAD'}
                extra={btoa(JSON.stringify({
                  inventoryProductId: open.id,
                  cost: form.getFieldValue('adultticketcost'), type: 'adult',
                  expiry: form.getFieldValue('expirebefore')? 
                  form.getFieldValue('expirebefore').format('DD-MM-YYYY'): null,
                  parser: form.getFieldValue('parser')
                }))}
                title='Select Adult Ticket'
                accept={form.getFieldValue('parser').toUpperCase().startsWith('XLSX') ? '.xlsx' :'application/pdf,'}
                validateFile={(e: any)=> {
                  form.validateFields(['adultticketcost', 'expirebefore'])
                  if (form.getFieldValue('expirebefore') && form.getFieldValue('adultticketcost')) {
                    return true;
                  }
                  return false;
                  }
                }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={12} className='mobileViewFullScreen'>
            <Form.Item name="childticketcost" label="Cost per child ticket"
            rules={[
              {
                required: true,
                message: "Please enter cost ",
              },
            ]}>
            <InputNumber placeholder='Cost Per child ticket' />
          </Form.Item>
        </Col>
        <Col span={5} className='mobileViewFullScreen'>
          <Form.Item name="childticket" >
            <FileuploadCommon 
                extra={btoa(JSON.stringify({
                  inventoryProductId: open.id,
                  cost: form.getFieldValue('childticketcost'), type: 'child',
                  expiry: form.getFieldValue('expirebefore') ? 
                  form.getFieldValue('expirebefore').format('DD-MM-YYYY') : null,
                  parser: form.getFieldValue('parser')
                })) }
              componentName={'INVENTORY_UPLOAD'}
              title='Select Child Ticket'
                validateFile={(e: any) => {
                  form.validateFields(['childticketcost', 'expirebefore'])
                  if (form.getFieldValue('expirebefore') && form.getFieldValue('childticketcost')) {
                    return true;
                  }
                  return false;
                }
                }
              
            />
          </Form.Item>
        </Col>
      </Row>
      </> : null}
    </Form>
  </Modal>
}



