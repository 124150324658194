import React, { useEffect, useState } from 'react';
import {
    GroupOutlined,
    FileOutlined,
    HomeOutlined,
    QuestionCircleOutlined,
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    LogoutOutlined,
    UserOutlined,
} from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Avatar, Badge, Popover, Button, Col, Layout, List, Row, Space, theme,
    Descriptions } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { dologout, healthCheck, sessionVerifyApi } from '../security/slice-login';
import { getAllMasterType } from './slice-master';

function parseJwt(token:any) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

const { Header } = Layout;

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
): MenuItem {

    return {
        key,
        icon,
        children,
        label,
    } as MenuItem;
}

const HeaderComponent = ({ setCollapsed, collapsed } : any) => {
    const dispatch = useDispatch()
    const nav = useNavigate();
    const location = useLocation()
    const [open, setOpen] = useState(false);

    const hide = () => {
        setOpen(false);
    };

    const handleOpenChange = (newOpen: boolean) => {
        setOpen(newOpen);
    };
    //check for login token and redirect to login
    const { userinfo, data } = useSelector((state: any) => state.login)
    useEffect(() => { if (!data || data.lenght < 0) nav("/login") }, [data])
    useEffect(() => {
        dispatch(getAllMasterType());
      }, [])
    //call session verify api
    useEffect(() => {
        dispatch(sessionVerifyApi())
        let d = new Date();
        dispatch(healthCheck({
            timeog: d.toISOString(),
            datetime: d.toISOString(),
            offdatetime: d.toISOString()
        }))
        const intervalId = setInterval(() => {
           
            
            dispatch(sessionVerifyApi())
        }, 150000)
        return () => {
            clearInterval(intervalId)
        }
    }, [])


    
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();

    return (
                <Header style={{ padding: 0, background: colorBgContainer, }} >
                    <Row gutter={10} className='position-relative mrl-0'>
                        <Col className='LogoMobileView'>
                            <span></span>
                        </Col>
                        <Col span={4} className='sliderButton'>
                            <Button
                                type="text"
                                icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                                onClick={() => setCollapsed(!collapsed)}
                                style={{
                                    fontSize: '16px',
                                    width: 64,
                                    height: 64,
                                }}
                            />

                        </Col><Col span={6} className='rightHeaderIcons' style={{ textAlign: 'right' }}>
                    <Popover
                        title="Profile"
                        content={<>
                            <Descriptions  style={{ width: '244px' }} column={1}>
                                <Descriptions.Item label="UserName">{userinfo?.userName}</Descriptions.Item>
                                <Descriptions.Item label="Email">{userinfo?.email}</Descriptions.Item>
                                <Descriptions.Item label="Role">{userinfo?.userRole}</Descriptions.Item>
                            </Descriptions>
                            <Button
                                type="text"
                                icon={<LogoutOutlined />}
                                onClick={() => {
                                    dispatch(dologout(''))
                                }}
                            >Logout</Button>
                        </>}                        
                        trigger="click"
                        open={open}
                        onOpenChange={handleOpenChange}
                    >
                            <Space>
                                <QuestionCircleOutlined style={{ fontSize: '20px' }} />
                                <Badge count={1}>
                                    <Avatar shape="square" icon={<UserOutlined />} />
                                </Badge>
                            </Space>
                    </Popover>
                        </Col>
                    </Row>
                </Header>
    );
}
export { HeaderComponent };




