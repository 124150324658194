import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'
import type { AsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { ThunkHttpClient } from '../utils/httpThunkClient'
import { stat } from 'fs'
import { popMessage } from '../../App'


// First, create the thunk
export const loginApiFunction = ThunkHttpClient.post('login/fetchByIdStatus',  (data)=>({url:"perform-login", body: data}))

export const sessionVerifyApi = ThunkHttpClient.get('login/sessionverify',  (data)=>(`session-verify?datetimestring=`+new Date().toISOString()))

export const healthCheck = ThunkHttpClient.post('login/healthcheck',   (data)=>({url:`healthcheck`, body: data}))


export const resetPassApiFunction = ThunkHttpClient.post('login/resetpass',  (data)=>({url:`reset-password?token=${data.token}`, body: {password: data.password}}))


export const resetPassrequestApiFunction = ThunkHttpClient.post('login/forgot',  (email)=>({url:`request-reset`, body: {email}}))

// Define a type for the slice state


// Define the initial state using that type
const initialState: any = {
  loading: false,
  data: undefined,
  resetloading: false,
  resetrequestloading: false,
  resetdata: undefined,
  userinfo: undefined
}

export const counterSlice = createSlice({
  name: 'login',
  initialState,
  reducers: { 
    checkLogin: (state, payload) => {
      const token = window.localStorage.getItem('token')
      if(token && token.length > 10) {
        state.data = token;
      } else {
        state.data = undefined
      }
    },
    dologout: (state, payload) => {
      window.localStorage.setItem('token', "")
      state.data = undefined;
      setTimeout(() => window.location.href = '/',10)
    }
  },
  extraReducers: (builder) => {
    builder.addCase(sessionVerifyApi.rejected, (state, action) => {
      if(action.error.message) {
        state.data = undefined;
        window.localStorage.setItem('token','');
      }
    })
    builder.addCase(sessionVerifyApi.fulfilled, (state, action) => {
      if(action.payload)
      state.userinfo = action.payload.data.user;
    })
    builder.addCase(loginApiFunction.fulfilled, (state, action) => {
      state.loading = false;
      if(action && action.payload) {
      state.data = action.payload.data
      window.localStorage.setItem('token', action.payload.data)
      }
    })
    builder.addCase(loginApiFunction.pending, (state, action) => {
      state.loading = true;
    })
    builder.addCase(loginApiFunction.rejected, (state, action) => {
     state.loading = false;
    })

    builder.addCase(resetPassApiFunction.fulfilled, (state, action) => {
      state.resetloading = false;
      state.resetdata = action.payload.data
      popMessage({type:'info', content: action.payload.data})
      window.localStorage.setItem('token', '')
      setTimeout(()=>window.location.href= '/', 1000)
    })
    builder.addCase(resetPassApiFunction.pending, (state, action) => {
      state.resetloading = true;
    })
    builder.addCase(resetPassApiFunction.rejected, (state, action) => {
     state.resetloading = false;
    })



    builder.addCase(resetPassrequestApiFunction.fulfilled, (state, action) => {
      state.resetrequestloading = false;
      state.resetrequestdata = action.payload.data
      popMessage({type:'info', content: action.payload.data})
      window.localStorage.setItem('token', '')
      setTimeout(()=>window.location.href= '/', 1000)
    })
    builder.addCase(resetPassrequestApiFunction.pending, (state, action) => {
      state.resetrequestloading = true;
    })
    builder.addCase(resetPassrequestApiFunction.rejected, (state, action) => {
     state.resetrequestloading = false;
    })

    builder.addCase(healthCheck.fulfilled, (state, action) => {
      
    })
    builder.addCase(healthCheck.pending, (state, action) => {
      
    })
    builder.addCase(healthCheck.rejected, (state, action) => {
     
    })
    
  },
})


export const { checkLogin, dologout } = counterSlice.actions
export default counterSlice.reducer